<template>
  <a-modal width="60%" v-model="visible" :footer="null" :title="'欠款明细'" :maskClosable="false">
    <!-- 查询表单 -->
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
      <a-row :gutter="8">
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="交易方式">
            <DictSelect
              field="dealType"
              :value.sync="searchData.dealType"
              style="width: 100%"
              placeholder="请选择交易类型"
            ></DictSelect>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="单号">
            <a-input v-model="searchData.dealNo" placeholder="请输入单号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xxl="6" :xl="5" :md="8" sm="2">
          <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
            <a-button type="primary" @click="getList()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :rowKey="(record) => record.id"
      bordered
      :maskClosable="false"
      :pagination="page"
      @change="changeTable"
    >
      <span slot="dealType" slot-scope="text">
        <a-tag color="" v-if="text == 1">支付</a-tag>
        <a-tag color="" v-if="text == 2">充值</a-tag>
        <a-tag color="" v-if="text == 3">订单取消</a-tag>
        <a-tag color="" v-if="text == 4">订单关闭</a-tag>
        <a-tag color="" v-if="text == 5">平台操作</a-tag>
        <a-tag color="" v-if="text == 6">订单修改时退回</a-tag>
        <a-tag color="" v-if="text == 7">订单删除</a-tag>
        <a-tag color="" v-if="text == 8">审核拒绝</a-tag>
        <a-tag color="" v-if="text == 9">额度过期</a-tag>
        <a-tag color="" v-if="text == 10">额度禁用</a-tag>
        <a-tag color="" v-if="text == 11">额度删除</a-tag>
        <a-tag color="" v-if="text == 12">发货单取消</a-tag>
        <a-tag color="" v-if="text == 13">发货单驳回</a-tag>
        <a-tag color="" v-if="text == 14">额度返审</a-tag>
        <a-tag color="" v-if="text == 15">延期充值</a-tag>
        <a-tag color="" v-if="text == 16">还款</a-tag>
      </span>
      <span slot="dealNo" slot-scope="text, row">
              <a @click="checkOrderInfo(row.dealNo, row.dealType)">{{ text }}</a>
            </span>
      <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
      <span slot="payAmount" slot-scope="text,row">
        <span style="color: red" v-if="row.amountType==2">(-) </span>
        <span style="color: green" v-if="row.amountType==1">(+) </span>
        {{ text | formatMoney }}
      </span>
    </a-table>
    <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
    <OrderShipInfoEditModal ref="OrderShipInfoEditModal"></OrderShipInfoEditModal>
    <FinanceReceiptInfoModal ref="FinanceReceiptInfoModal"></FinanceReceiptInfoModal>

    <div style="text-align: right; margin-top: 14px">
      <a-button type="default" @click="toCancel">关闭</a-button>
    </div>
  </a-modal>
</template>
<script>
import OrderShipInfoEditModal from '@/views/order_ship_info/components/OrderShipInfoEditModal'
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal'
import FinanceReceiptInfoModal from '@/views/finance_receipt/components/FinanceReceiptInfoModal';

const qs = require('qs')
export default {
  name: 'DealerBalanceDetailed',
  components: {
    OrderInfoCheckModal,
    OrderShipInfoEditModal,
    FinanceReceiptInfoModal,
  },
  data() {
    return {
      columns: [
        {
          title: '交易时间',
          dataIndex: 'dealDate',
          align: 'center',
          key: 'dealDate',
        },
        {
          title: '交易类型',
          dataIndex: 'dealType',
          align: 'center',
          key: 'dealType',
          scopedSlots: { customRender: 'dealType' },
        },
        {
          title: '交易前金额（元）',
          dataIndex: 'amount',
          align: 'center',
          key: 'amount',
          scopedSlots: {customRender: 'amount'}
        },
        {
          title: '交易金额（元）',
          dataIndex: 'dealAmount',
          align: 'left',
          key: 'dealAmount',
          scopedSlots: {customRender: 'payAmount'}
        },
        {
          title: '交易后金额（元）',
          dataIndex: 'balance',
          align: 'center',
          key: 'balance',
          scopedSlots: {customRender: 'amount'}
        },
        {
          title: '关联单号',
          dataIndex: 'dealNo',
          align: 'center',
          key: 'dealNo',
          scopedSlots: {customRender: 'dealNo'}
        },
      ],
      visible: false,
      tableData: [],
      searchData: {}, // 搜索条件
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      rowData: {},
      dealerId: 0,
    }
  },
  mounted() {
    // this.getData()
  },
  methods: {
    init(row) {
      this.visible = true
      this.rowData = row
      this.dealerId = row.dealerId
      this.getData()
    },
    toCancel() {
      this.visible = false
    },
    getData() {
      let params = {
        dealerId: this.dealerId,
        startDate: this.rowData.startTime,
        endDate: this.rowData.endTime,
        pageSize: this.page.pageSize,
        pageNumber: this.page.current,
      }
      const postData = Object.assign(params, this.searchData)
      this.axios
        .get(`/api/dealer/limit/limitInfoPayRecord/list?${qs.stringify(postData, {arrayFormat: 'repeat',})}`)
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    // 查询
    getList() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },

    // 重置
    reset() {
      this.page.current = 1
      this.page.pageSize = 10
      this.searchData = {}
      this.getData()
    },
    //打开上游单据
    checkOrderInfo(orderCode, dealType) {
      console.log(orderCode, dealType)
      const data = {
        orderCode: orderCode,
      }
      if (dealType == 3) {
        this.$refs.FinanceReceiptInfoModal.setRowDataByCode(orderCode, 'check')
      } else {
        if (orderCode.indexOf('DO') !== -1) {
          this.$refs.OrderShipInfoEditModal.setRowDataByCode(data, 'check')
        } else {
          this.$refs.OrderInfoCheckModal.isShowByOrderCode(data, 'check')
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.DH {
  color: rgb(27, 172, 229);
  cursor: pointer;
  text-decoration: underline;
}
</style>